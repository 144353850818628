import { Booking } from '@wix/ambassador-bookings-server/types';
import { CatalogData, Service, ServiceType } from '@wix/bookings-uou-types';
import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import {
  QueryAvailabilityResponse,
  QueryAvailabilityRequest,
  Slot,
} from '@wix/ambassador-availability-calendar/types';
import {
  getEndOfDay,
  getLocalDateTimeStartOfDay,
} from '../utils/dateAndTime/dateAndTime';
import { createDummyCatalogData } from './dummyData/dummyCatalogData';
import { createDummySlots } from './dummyData/dummySlotsData';
import { createDummyDateAvailability } from './dummyData/dummyDateAvailability';
import { BookingsApi } from './BookingsApi';
import { CalendarApiInitParams, SlotsAvailabilityFilter } from './types';
import { Optional } from '../types/types';
import { CatalogErrorType } from '../components/BookingCalendar/ViewModel/emptyStateViewModel/emptyStateViewModel';
import settingsParams from '../components/BookingCalendar/settingsParams';
import { CalendarState } from '../components/BookingCalendar/controller';

export const CALENDAR_PAGE_URL_PATH_PARAM = 'booking-calendar';

export class CalendarApi {
  private wixSdkAdapter: WixOOISDKAdapter;
  private bookingsApi: BookingsApi;

  constructor({ wixSdkAdapter }: CalendarApiInitParams) {
    this.wixSdkAdapter = wixSdkAdapter;
    this.bookingsApi = new BookingsApi({
      authorization: wixSdkAdapter.getInstance(),
      baseUrl: wixSdkAdapter.getServerBaseUrl(),
    });
  }

  async getCatalogData(
    onError: (type: CatalogErrorType) => void,
  ): Promise<Optional<CatalogData>> {
    if (this.wixSdkAdapter.isEditorMode()) {
      return createDummyCatalogData();
    }

    const serviceSlug = await this.wixSdkAdapter.getServiceSlug(
      CALENDAR_PAGE_URL_PATH_PARAM,
    );
    const resourceSlug = this.getResourceSlug();

    try {
      const catalogData: CatalogData = await this.bookingsApi.getCatalogData({
        serviceSlug,
        resourceSlug,
      });

      const {
        services: [service],
      } = catalogData;

      if (!service) {
        onError(CatalogErrorType.SERVICE_NOT_FOUND);
        return;
      }

      if (!this.isCalendarFlow(service)) {
        await this.wixSdkAdapter.navigateToBookingsServicePage(serviceSlug);
        return;
      }

      return catalogData;
    } catch (e) {
      onError(CatalogErrorType.SERVER_ERROR);
    }
  }

  async getNextAvailableDate(slotsAvailabilityFilter: SlotsAvailabilityFilter) {
    // or todo
    // async getNextAvailableDate(fromLocalDateTime: string,
    //     { state, settings }: { state: CalendarState; settings: any }) {
    //
    // const to = fromLocalDateTime + 3 month;
    // const availabilityCalendarRequest: QueryAvailabilityRequest = this.buildQueryAvailabilityRequest(
    //   fromLocalDateTime,
    //   to,
    //   { state, settings },
    // );
    // const slotsAvailability = this.bookingsApi.getSlotsAvailability(availabilityCalendarRequest);

    const dateAvailabilityResponse = await this.getDateAvailability(
      slotsAvailabilityFilter,
    );
    const nextAvailableDate = dateAvailabilityResponse.nextAvailable?.date;

    return nextAvailableDate && getLocalDateTimeStartOfDay(nextAvailableDate);
  }

  getDateAvailability(slotsAvailabilityFilter: SlotsAvailabilityFilter) {
    if (this.wixSdkAdapter.isEditorMode()) {
      return createDummyDateAvailability();
    }
    // prepare slot availability filter : {from, to: from + end of month, timezone}
    return this.bookingsApi.getDateAvailability(slotsAvailabilityFilter);
  }

  async getSlotsForSelectedDate(
    selectedLocalDateTime: string,
    { state, settings }: { state: CalendarState; settings: any },
  ): Promise<QueryAvailabilityResponse> {
    if (this.wixSdkAdapter.isEditorMode()) {
      return createDummySlots();
    }
    const to = getEndOfDay(selectedLocalDateTime);
    const availabilityCalendarRequest: QueryAvailabilityRequest = this.buildQueryAvailabilityRequest(
      selectedLocalDateTime,
      to,
      { state, settings },
    );

    return this.bookingsApi.getSlotsAvailability(availabilityCalendarRequest);
  }

  async getBookingDetails(bookingId: string): Promise<Optional<Booking>> {
    return this.bookingsApi.getBookingDetails(bookingId);
  }

  async rescheduleBooking({
    bookingId,
    service,
    slot,
    timezone,
  }: {
    bookingId: string;
    service: Service;
    slot: Slot;
    timezone: string;
  }) {
    if (this.isServiceAClass(service)) {
      return this.bookingsApi.rescheduleClassBooking({
        bookingId,
        sessionId: slot.id!,
      });
    } else {
      return this.bookingsApi.rescheduleAppointmentBooking({
        bookingId,
        scheduleId: slot.scheduleId!,
        timezone,
        start: slot.start!,
        end: slot.end!,
        staffMembersScheduleIds: [slot.resource!.scheduleId!],
      });
    }
  }

  private isServiceAClass = (service: Service) =>
    service.info.type === ServiceType.GROUP;

  private isCalendarFlow = (service: Service) =>
    service.info.type !== ServiceType.COURSE &&
    service.policy.isBookOnlineAllowed;

  private getResourceSlug() {
    const staffQueryParam = this.wixSdkAdapter.getUrlQueryParamValue(
      BookingsQueryParams.STAFF,
    );

    if (staffQueryParam) {
      if (Array.isArray(staffQueryParam)) {
        return staffQueryParam[0];
      } else {
        return staffQueryParam;
      }
    }
  }

  private buildQueryAvailabilityRequest(
    fromLocalDateTime: string,
    to: string,
    { state, settings }: { state: CalendarState; settings: any },
  ) {
    const { selectedTimezone, filterOptions, selectedService } = state;
    const onlyAvailableSlots = settings.get(settingsParams.onlyAvailableSlots);
    const availabilityCalendarRequest: QueryAvailabilityRequest = {
      timezone: selectedTimezone,
      query: {
        filter: {
          serviceIds: [`${selectedService?.id}`],
          from: `${fromLocalDateTime}.000Z`,
          to,
          ...(onlyAvailableSlots === true
            ? { isBookable: onlyAvailableSlots }
            : {}),
          ...(filterOptions.STAFF_MEMBER?.length > 0
            ? { resourceId: filterOptions.STAFF_MEMBER }
            : {}),
          ...(filterOptions.LOCATION?.length > 0
            ? { 'location.businessLocation.id': filterOptions.LOCATION }
            : {}),
        },
      },
    };
    return availabilityCalendarRequest;
  }
}
