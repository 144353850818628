import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import {
  DialogState,
  DialogType,
} from '../../ViewModel/dialogViewModel/dialogViewModel';
import {
  CalendarErrors,
  SetError,
  WidgetElements,
} from '../../../../utils/bi/consts';
import { CalendarState } from '../../controller';
import { getSelectedSlots } from '../onSubmit/onSubmit';
import { SetCalendarErrors } from '../setCalendarErrors/setCalendarErrors';
import { CloseDialogAction } from '../closeDialog/closeDialog';
import { mapDialogTypeToWidgetComponent } from '../../../../utils/bi/mappers';

export type OnDialogConfirm = () => void;

export function createOnDialogConfirmAction(
  {
    getControllerState,
    context: { biLogger, calendarApi, wixSdkAdapter, businessInfo, t },
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  closeDialog: CloseDialogAction,
  setCalendarErrors: SetCalendarErrors,
): OnDialogConfirm {
  return async () => {
    const [state, setState] = getControllerState();
    const {
      selectedService,
      selectableSlotsAtSelectedTime,
      dialog,
      selectedBookingPreferences,
      rescheduleBookingDetails,
      selectedTimezone,
    } = state;

    void biLogger.bookingsCalendarClick({
      component: mapDialogTypeToWidgetComponent(dialog?.type),
      element: WidgetElements.CONFIRM_BUTTON,
    });

    if (dialog!.type === DialogType.RescheduleConfirm) {
      const selectedSlot = getSelectedSlots({
        selectableSlotsAtSelectedTime: selectableSlotsAtSelectedTime!,
        dateRegionalSettingsLocale: businessInfo.dateRegionalSettingsLocale!,
        t,
        selectedBookingPreferences,
      })[0].slot!;
      const bookingId = rescheduleBookingDetails!.id!;
      try {
        setState({
          dialog: {
            type: DialogType.RescheduleConfirm,
            state: DialogState.LOADING,
          },
        });
        await calendarApi.rescheduleBooking({
          bookingId,
          slot: selectedSlot,
          service: selectedService,
          timezone: selectedTimezone!,
        });
        await wixSdkAdapter.navigateToMembersArea();
      } catch (e) {
        setCalendarErrors(CalendarErrors.RESCHEDULE_SERVER_ERROR, SetError.ADD);
        closeDialog();
      }
    } else if (
      dialog!.type === DialogType.JoinWaitlist ||
      dialog!.type === DialogType.PremiumViewer
    ) {
      closeDialog();
    }
  };
}
