import {
  createDummyBookingDetailsViewModel,
  createBookingDetailsViewModel,
  BookingDetailsViewModel,
} from './bookingDetailsViewModel/bookingDetailsViewModel';
import { CalendarState } from '../controller';
import { ViewModelFactoryParams } from '../../../utils/ControlledComponent/ControlledComponent.types';
import {
  createHeaderViewModel,
  HeaderViewModel,
} from './headerViewModel/headerViewModel';
import { CalendarContext } from '../../../utils/context/contextFactory';
import {
  createNoAvailabilityViewModel,
  NoAvailabilityViewModel,
} from './noAvailabilityViewModel/noAvailabilityViewModel';
import {
  createWidgetViewModel,
  WidgetViewModel,
} from './widgetViewModel/widgetViewModel';
import {
  TimePickerViewModel,
  createTimePickerViewModel,
} from './timePickerViewModel/timePickerViewModel';
import {
  createDatePickerViewModel,
  DatePickerViewModel,
} from './datePickerViewModel/datePickerViewModel';
import {
  createRescheduleDetailsViewModel,
  RescheduleDetailsViewModel,
} from './rescheduleDetailsViewModel/rescheduleDetailsViewModel';
import {
  createDialogViewModel,
  DialogViewModel,
} from './dialogViewModel/dialogViewModel';
import {
  createToastViewModel,
  ToastViewModel,
} from './toastViewModel/toastViewModel';
import { EmptyStateViewModel } from './emptyStateViewModel/emptyStateViewModel';

export type CalendarViewModel = {
  widgetViewModel: WidgetViewModel;
  headerViewModel: HeaderViewModel;
  rescheduleDetailsViewModel?: RescheduleDetailsViewModel;
  noAvailabilityViewModel: NoAvailabilityViewModel;
  timePickerViewModel: TimePickerViewModel;
  datePickerViewModel: DatePickerViewModel;
  bookingDetailsViewModel: BookingDetailsViewModel;
  dialogViewModel: DialogViewModel;
  toastViewModel: ToastViewModel;
  emptyStateViewModel?: EmptyStateViewModel;
};

export async function createCalendarViewModel(
  viewModelFactoryParams: ViewModelFactoryParams<
    CalendarState,
    CalendarContext
  >,
): Promise<CalendarViewModel> {
  return {
    widgetViewModel: createWidgetViewModel(viewModelFactoryParams),
    headerViewModel: createHeaderViewModel(viewModelFactoryParams),
    rescheduleDetailsViewModel: createRescheduleDetailsViewModel(
      viewModelFactoryParams,
    ),
    noAvailabilityViewModel: createNoAvailabilityViewModel(
      viewModelFactoryParams,
    ),
    timePickerViewModel: createTimePickerViewModel(viewModelFactoryParams),
    datePickerViewModel: createDatePickerViewModel(viewModelFactoryParams),
    bookingDetailsViewModel: await createBookingDetailsViewModel(
      viewModelFactoryParams,
    ),
    dialogViewModel: createDialogViewModel(viewModelFactoryParams),
    toastViewModel: createToastViewModel(viewModelFactoryParams),
  };
}

export async function createDummyCalendarViewModel(
  viewModelFactoryParams: ViewModelFactoryParams<
    CalendarState,
    CalendarContext
  >,
): Promise<CalendarViewModel> {
  const calendarViewModel = await createCalendarViewModel(
    viewModelFactoryParams,
  );
  calendarViewModel.bookingDetailsViewModel = createDummyBookingDetailsViewModel(
    viewModelFactoryParams,
  );
  return calendarViewModel;
}
