import { IWidgetControllerConfig } from '@wix/native-components-infra/dist/src/types/types';
import {
  ActionsFactory,
  SetState,
  StateChangeCallback,
  GetControllerState,
  ViewModelFactory,
} from './ControlledComponent.types';

export async function createControlledComponent<
  ControllerStateType,
  ControllerActionTypes,
  ControllerViewModelType,
  ContextType
>({
  controllerConfig,
  initialState,
  viewModelFactory,
  actionsFactory,
  context,
}: {
  controllerConfig: IWidgetControllerConfig;
  initialState: ControllerStateType;
  viewModelFactory: ViewModelFactory<
    ControllerStateType,
    ControllerViewModelType,
    ContextType
  >;
  actionsFactory: ActionsFactory<
    ControllerStateType,
    ControllerActionTypes,
    ContextType
  >;
  context: ContextType;
}): Promise<{
  onStateChange: (callback: StateChangeCallback<ControllerStateType>) => void;
}> {
  const { setProps } = controllerConfig;
  let state: ControllerStateType = initialState;
  const stateChangedListeners: StateChangeCallback<ControllerStateType>[] = [];

  const onStateChange = (
    callback: StateChangeCallback<ControllerStateType>,
  ) => {
    stateChangedListeners.push(callback);
  };

  const setState: SetState<ControllerStateType> = (
    stateToUpdate: Partial<ControllerStateType>,
  ) => {
    state = { ...state, ...stateToUpdate };
    stateChangedListeners.forEach((listener) => listener(state));
    render();
  };

  const getControllerState: GetControllerState<ControllerStateType> = () => [
    state,
    setState,
  ];

  const controllerActions = actionsFactory({
    getControllerState,
    context,
  });

  const render = async () => {
    const viewModel = await viewModelFactory({
      state,
      context,
    });
    setProps({ ...viewModel, ...controllerActions });
  };

  await render();

  return { onStateChange };
}
