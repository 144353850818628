import { TFunction } from '../../controller';
import { Optional } from '../../../../types/types';

export type EmptyStateViewModel = {
  title: string;
  subtitle: string;
};
export enum CatalogErrorType {
  SERVICE_NOT_FOUND = 'SERVICE_NOT_FOUND',
  SERVER_ERROR = 'SERVER_ERROR',
}

export function createEmptyStateViewModel({
  t,
  type,
}: {
  t: TFunction;
  type: CatalogErrorType;
}): Optional<EmptyStateViewModel> {
  if (type === CatalogErrorType.SERVICE_NOT_FOUND) {
    return {
      title: t('app.empty-state.no-service.title'),
      subtitle: t('app.empty-state.no-service.subtitle'),
    };
  }
  if (type === CatalogErrorType.SERVER_ERROR) {
    return {
      title: t('app.empty-state.server-error.title'),
      subtitle: t('app.empty-state.server-error.subtitle'),
    };
  }
}
